import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content, { HTMLContent } from "../components/Content"

const AboutPageTemplate = props => {
  const PageContent = props.contentComponent || Content

  const About = styled(PageContent)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 700px;
    font-family: Roboto Condensed;
    min-height: calc(100vh - 200px - 24px);
    
    @media (min-width: 650px) {
      min-height: calc(100vh - 105px - 24px);
    }

    h2 {
      display: inline-flex;
      font-family: Teko;
      color: #fff;
      background: #000;
      text-decoration: none;
      padding: 3px 20px 0;
      align-items: center;
      line-height: 1.25;
      font-size: 26px;
      font-weight: normal;
      margin: 0;
    }

    h3 {
      display: inline-flex;
      font-family: Teko;
      color: #000;
      background: none;
      text-decoration: none;
      padding: 0;
      align-items: center;
      line-height: 1.25;
      font-size: 34px;
      font-weight: normal;
      margin: 10px 0 0;
    }

    ul {
      list-style-position: inside;
      padding-left: 30px;
    }

    li {
      text-indent: -58px;
      margin-left: 32px;
    }
  `

  return <About content={props.content} />
}

const AboutPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout withHeader backUrl="/">
      <SEO title={page.frontmatter.title} />
      <AboutPageTemplate contentComponent={HTMLContent} content={page.html} />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
